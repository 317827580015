import BoardsAppImg from '../../images/boardsapp.JPG';
import SuperStoreImg from '../../images/superstore.JPG';
import DropboxCloneImg from '../../images/dstore.JPG';
import CoinsTrackerImg from '../../images/cointracker.JPG';

export const projects = [
  {
    name: 'Boards App',
    desc: "An app aimed to improve collaboration amongst team members. A login/register screen allows secure access for users. Users have the ability to create new boards, add friends, upload images, and comment on existing boards. MongoDB used to store user and board data. AWS is used to store any images uploaded by users.",
    used: ['MongoDB', 'Express', 'React', 'Node.js', 'AWS'],
    githubLink: 'https://github.com/z-harper/boards-app',
    image: BoardsAppImg,
  },
  {
    name: 'Super Store',
    desc: "An online shopping store that allows users to browse items and add/remove items from their cart. The app uses custom pagination and gives users the ability to search for items via the searchbar. Redux used to handle state changes to a user's cart.",
    used: ['React', 'Redux'],
    githubLink: 'https://github.com/z-harper/store-v2',
    image: SuperStoreImg,
  },
  {
    name: 'Blockchain Dropbox Clone',
    desc: "An app that interacts with both a local Ethereum blockchain and InterPlanetary File System (IPFS). It allows users to upload files to IPFS and uses the blockchain to keep a record of the uploads via the app so users can refer back to the uploaded files.",
    used: ['React', 'Solidity', 'IPFS', 'ethers'],
    githubLink: 'https://github.com/z-harper/dropbox-clone',
    image: DropboxCloneImg,
  },
  {
    name: 'Cryptocurrency Coins Tracker',
    desc: "An app which makes API requests to CoinGecko to track the latest cryptocurrency prices on Binance exchange. It allows user to toggle between light and dark mode and also allows users to search coins on Binance. A user can add coins to the sidebar and view a 24 hour candlestick chart for each coin selected.",
    used: ['React', 'styled-components'],
    githubLink: 'https://github.com/z-harper/coin-tracker',
    image: CoinsTrackerImg,
  },
]