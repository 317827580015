export const sections = [
  {
    id: 1,
    displayAs: 'About',
    linkTo: 'about',
  },
  {
    id: 2,
    displayAs: 'Skills',
    linkTo: 'skills',
  },
  {
    id: 3,
    displayAs: 'Projects',
    linkTo: 'projects',
  },
  {
    id: 4,
    displayAs: 'Contact',
    linkTo: 'contact',
  },
]