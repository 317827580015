export const skillChartPoints = [
  {
    name: 'Javascript',
    percent: '75%',
  },
  {
    name: 'React',
    percent: '75%',
  },
  {
    name: 'HTML',
    percent: '75%',
  },
  {
    name: 'CSS',
    percent: '75%',
  },
  {
    name: 'Node',
    percent: '65%',
  },
  {
    name: 'Express',
    percent: '65%',
  },
  {
    name: 'MongoDB',
    percent: '60%',
  },
  {
    name: 'Solidity',
    percent: '45%',
  },
  {
    name: 'Python',
    percent: '50%',
  },
  {
    name: 'Sql Server',
    percent: '80%',
  },
]

export const skillDescriptions = [
  {
    header: 'Computer Science Graduate',
    text: 'Relevant courses include Object-Oriented Software Design, Database Theory/Design, Software Systems, Data Mining for Software Engineers, Platform Based Development, Design and Analysis of Algorithms.',
  },
  {
    header: 'Data Analyst in Past Life',
    text: 'Data surrounds us. Harnessing it helps us reach our goals by giving us the ability to make informed decisions based on facts, trends, and statistical analysis.',
  },
  {
    header: 'A Desire to Learn',
    text: 'Everyday is a new opportunity to learn something new. Approaching each day with this mentality keeps my mind open to new possibilities of how I design, write, test, and implement code.',
  },
]