const quotes = [
  {
    quote: 'If debugging is the process of removing software bugs, then programming must be the process of putting them in.',
    author: 'Edsger Dijkstra',
  },
  {
    quote: 'Computer Science is a science of abstraction -creating the right model for a problem and devising the appropriate mechanizable techniques to solve it.',
    author: 'Alfred Aho',
  },
  {
    quote: 'Code never lies, comments sometimes do.',
    author: 'Ron Jeffries',
  },
  {
    quote: "Coding is today's language of creativity. All our children deserve a chance to become creators instead consumers of computer science.",
    author: 'Maria Klawe',
  },
  {
    quote: 'Imagination is more important than knowledge.',
    author: 'Albert Einstein',
  },
  {
    quote: 'Computer science is no more about computers than astronomy is about telescopes.',
    author: 'Edsger Dijkstra',
  },
  {
    quote: 'To err is human, but to really foul things up you need a computer.',
    author: 'Paul R. Ehrlich',
  },
  {
    quote: 'Any fool can write code that a computer can understand. Good programmers write code that humans can understand.',
    author: 'Martin Fowler',
  },
  {
    quote: "I have not failed. I've just found 10,000 ways that won't work.",
    author: 'Thomas A. Edison',
  },
  {
    quote: 'I hear and I forget. I see and I remember. I do and I understand.',
    author: 'Confucious',
  },
  {
    quote: "The goal of Computer Science is to build something that will last at least until we've finished building it.",
    author: 'William C. Brown',
  },
  {
    quote: 'People think that computer science is the art of geniuses but the actual reality is the opposite, just many people doing things that build on each other, like a wall of mini stones.',
    author: 'Donald Knuth',
  },
]

export const generateQuote = () => {
  return quotes[Math.floor(Math.random() * quotes.length)];
}