import './contact.css';
import congrats from '../../images/undraw_well_done.svg';
import { generateQuote } from '../../utils/quotes';
import {motion, useAnimation} from 'framer-motion';
import {useState, useEffect} from 'react';
import {useInView} from 'react-intersection-observer';

function Contact() {
  const [quote, setQuote] = useState({});
  const {ref, inView} = useInView({
    threshold: 0.7
  });
  const fadeIn = useAnimation();

  useEffect(() => {
    setQuote(generateQuote());
  }, [])

  useEffect(() => {
    if (inView) {
      setQuote(generateQuote());
      fadeIn.start({
        opacity: 1,
        transition: { duration: 2, }
      });
    } else {
      fadeIn.start({ opacity: 0, });
    }
  }, [inView, fadeIn]);


  return (
    <div className='contact-wrap' id='contact'>
      <div className='contact'>
        <div className='contact-left'>
          <h3 ref={ref} className='section-title'>CONTACT</h3>
          <motion.div animate={fadeIn} className='quote-wrap'>
            <p className='quote'>{quote.quote}</p>
            <h5>- {quote.author}</h5>
          </motion.div>
          <div className='contact-text-wrap'>
            <h4 className='contact-header'>Thanks for checking out my page.</h4>
            <p className='contact-subhead'>If you'd like to learn more about me, or would just like to chat, please reach out.</p>
            <a className='get-in-touch' href='mailto:zjharper@protonmail.com' target='_blank' rel='noopener noreferrer'>
              Get in Touch
            </a>
          </div>
        </div>
        <div className='contact-right'>
          <img src={congrats} alt='congrats' />
        </div>
      </div>
    </div>
  )
}

export default Contact
