import './hero.css';
import Particles from 'react-particles-js';
import particlesConfig from './particles-config';
import { AiOutlineGithub, AiFillLinkedin, AiFillMail } from 'react-icons/ai';

function Hero() {
  return (
    <div className='hero-wrap' id='home'>
      <Particles params={particlesConfig} height='100%' width='100%' style={{position: 'absolute', zIndex: '-1', top: '0', left: '0'}} />
      <div className='hero-content'>
        <h3 className='hero-greeting'>Hello traveler, my name is</h3>
        <h1 className='hero-name'>Zach Harper</h1>
        <h3 className='hero-intro'>I like building things on the web...</h3>
        <ul className='social-icons'>
          <li className='social-link'>
            <a href='https://github.com/z-harper' target='_blank' rel='noopener noreferrer'>
              <AiOutlineGithub size={40} color='#d19106' />
            </a>
          </li>
          <li className='social-link'>
            <a href='https://www.linkedin.com/in/z-harper/' target='_blank' rel='noopener noreferrer'>
              <AiFillLinkedin size={40} color='#d19106' />
            </a>
          </li>
          <li className='social-link'>
            <a href='mailto:zjharper@protonmail.com' target='_blank' rel='noopener noreferrer'>
              <AiFillMail size={40} color='#d19106' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Hero
