import {useState} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';
import './skills.css';
import {skillChartPoints, skillDescriptions} from './skill-attribs';

function Skills() {
  const {ref, inView} = useInView();
  const childrenVariants = useAnimation();

  useEffect(() => {
    if (inView) {
      childrenVariants.start(index => ({
        opacity: 1,
        x: 0,
        transition: { 
          type: 'spring',
          bounce: 0.3,
          duration: 2, 
          delay: index * 0.3 
        },
      }))
    } 
    if (!inView) {
      childrenVariants.start({ 
        opacity: 0, 
        x: '100vw', 
        transition: { duration: 0.5,} 
      });
    }
  }, [inView, childrenVariants]);

  return (
    <section className='skills-wrap' id='skills'>
      <div ref={ref} className='skills'>
        <div className='skills-col-left'>
          <h3 className='section-title'>SKILLS</h3>
          {skillDescriptions.map((skill, index) => {
            return (
              <div key={index}>
                <h1 className='skill-header'>{skill.header}</h1>
                <p className='skill-text'>{skill.text}</p>
              </div>
            )
          })}
        </div>
        <ul className='skills-col-right'>
          {skillChartPoints.map((skill, index) => {
            return (
              <motion.div key={index} custom={index} animate={childrenVariants} whileHover={{ x: 15 }}>
                <ChartSkill skill={skill} />
              </motion.div>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

function ChartSkill({skill}) {
  const [isInHover, setIsInHover] = useState(false);
  
  return (
    <li 
      className='bar'      
      style={{width: skill.percent}} 
      onMouseEnter={() => setIsInHover(true)}
      onMouseLeave={() => setIsInHover(false)}
    >
      <span className='bar-name'>{skill.name}</span>
      {isInHover && <span className='bar-score'>{skill.percent}</span>}
    </li>
  )
}

export default Skills
