import './navbar.css';
import { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { sections } from '../../utils/sections';
import NavLogo from '../../images/navlogo.PNG';
import Resume from '../../resume/resume_harper_zach.pdf';

function Navbar({toggleSidebar}) {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  } 

  const scrollUp = () => {
    scroll.scrollToTop();
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, [])

  return (
    <div className='navbar-wrap' style={scrollNav ? {background: '#112554'} : {background: 'transparent'}}>
      <nav className='navbar'>
        <span className='logo-wrap' onClick={scrollUp}>
          <img src={NavLogo} alt='logo' width='65' height='45' />
        </span>
        <span className='open-icon-wrap' onClick={toggleSidebar} >
          <svg className='open-icon' width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <ul className='nav-links'>
          {sections.map(section => {
            return (
              <li className='link-wrap' key={section.id}>
                <Link className='link' activeClass='link-active' to={section.linkTo} exact='true' smooth={true} duration={500} spy={true} offset={-80}>
                  {section.displayAs}
                </Link>
              </li>
            )
          })}
          {/* <li className='link-wrap'>
            <button className='resume-link' onClick={() => window.open(Resume)} target='_blank' rel='noopener noreferrer'>    
              Resume
            </button>
          </li> */}
        </ul>
      </nav>
    </div>
  )
}

export default Navbar