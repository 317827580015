import './about.css';
import {motion, useAnimation} from 'framer-motion';
import {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';
import headshot from '../../images/headshot.jpg';

function About() {
  const {ref, inView} = useInView({
    threshold: 0.1
  });
  const slideIn = useAnimation();
  const fadeIn = useAnimation();

  useEffect(() => {
    if (inView) {
      slideIn.start({
        x: 0,
        transition: { type: 'spring', duration: 2, bounce: 0.3 }
      })
      fadeIn.start({
        opacity: 1,
        transition: { duration: 2, }
      })
    } else {
      slideIn.start({ x: '100vw', });
      fadeIn.start({ opacity: 0, });
    }
  }, [inView, fadeIn, slideIn]);

  return (
    <section ref={ref} className='about-wrap' id='about'>
      <div className='about'>
        <div className='about-col-left'>
          <motion.img animate={fadeIn} className='img-headshot' src={headshot} alt='headshot' />
        </div>
        <div className='about-col-right'>
          <h3 className='section-title'>ABOUT ME</h3>
          <h1 className='section-header'>A developer currently residing in Minneapolis.</h1>
          <motion.div animate={slideIn}>
            <p className='section-text'>Computer Science graduate with a background in data.</p>
            <p className='section-text'>Passionate about writing effective, efficient, readable code.</p>
            <p className='section-text'>Currently interested in working on full stack applications.</p>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default About