import './projects.css';
import {projects} from './projects-detailed';
import {motion, useAnimation} from 'framer-motion';
import {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';

function Projects() {
  return (
    <section className='project-wrap' id='projects'>
      <div className='projects'>
      <h3 className='section-title' style={{textAlign: 'center'}}>PROJECTS</h3>
      <div className='projects-card-wrap'>
        {projects.map((project, i) => {
          return (
            <ProjectCard key={i} project={project} />
          )
        })}
      </div>
      </div>
    </section>
  )
}

function ProjectCard({project}) {
  const {ref, inView} = useInView({ threshold: 0.3 });
  const fadeIn = useAnimation();

  useEffect(() => {
    if (inView) {
      fadeIn.start({ opacity: 1, transition: { duration: 2, }})
    } else {
      fadeIn.start({ opacity: 0, });
    }
  }, [inView, fadeIn]);

  return (
    <div ref={ref} className='project-card'>
      <div className='project-col-img'>
        <div className='project-img-wrap'>
          <motion.img animate={fadeIn} className='project-img' src={project.image} alt={project.name} />
        </div>
      </div>
      <div className='project-col-text'>
        <h3 className='project-name'>{project.name}</h3>
        <p className='project-desc'>{project.desc}</p>
        <span className='project-used'>{project.used.join(', ')}</span>
        <span><a className='github-link' href={project.githubLink}  target='_blank' rel='noopener noreferrer'>Code on GitHub</a></span>
      </div>
    </div>
  )
}

export default Projects
